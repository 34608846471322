import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import Button from "~/components/button/button";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={3}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 3</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={true}
              setFullScreen={setStudentFullScreen}
            >
              <p>Work in a team of 2-3 students for this step. In your notebook, create a table with two columns labeled with these headings:</p>

              <div className="flex space-x-5 justify-center text-2xl" style={{ fontFamily: "Chilanka" }}>
                <strong>Accurate</strong>
                <strong>Inaccurate</strong>
              </div>

              <p>You will be looking at a website for a diet supplement to help people lose weight. As you look through the website, try to find words or phrases that might make you think the information is likely to be accurate or inaccurate. Record those words or phrases in the table in your notebook.</p>

              <Button
                action="secondary"
                className="m-2"
                color="indigo"
                href="/green-coffee-magic"
                icon="external"
                title="Green coffee bean extract website"
              >
                Green coffee bean extract website
              </Button>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={true}
              setFullScreen={setTeacherFullScreen}
            >
              <p>Have students work in teams of 2–3 for this step, which gives them the chance to see if they can identify words or phrases on a website that relate to accuracy. Give students 5–10 minutes to list such words they see on the green coffee bean extract website that might suggest accurate or inaccurate information.</p>
              <p>Ask for volunteers to share words or phrases that they thought suggested accurate or inaccurate information. Ask them to explain their reasons. Some teams may have different ideas, which is OK if their reasons align with their ideas.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
